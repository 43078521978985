import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { PaymentMethod, Reservation, ReservationResponse, ShoppingCart } from '../interfaces/shopping-cart.interface';
import { User, SessionRequest, SessionResponse } from '../interfaces/user.interface';
import { ResetPasswordRequest, EmailValidation, AuthenticationContext } from '../interfaces/auth.interface';
import { OrderRequest, OrderResponse } from '../interfaces/order.interface';
import { Shop } from '../interfaces/shop.interface';
import { ShopEvent } from 'src/app/model/event.model';

@Injectable({
  providedIn: 'root',
})
export class ShopApiService {
  constructor(private http: HttpClient) {}

  getShop = (name: string, source: string, campaign: string) => {
    let params = new HttpParams();

    if (source) {
      params = params.append('source', source);
    }
    if (campaign) {
      params = params.append('campaign', campaign);
    }

    return this.http.get<Shop>(`api/shop/${name}`, { params: params });
  };

  getShoppingCart = (shoppingCartUuid: string, withSalt?: boolean) => {
    const salt = new Date().getTime();

    return this.http.get<ShoppingCart>(`v1.0/shopping-carts/${shoppingCartUuid}${withSalt ? '?' + salt : ''}`);
  };

  getUnfinishedCarts = () => {
    return this.http.get<{ data: ShoppingCart[] }>(`v1.0/shopping-carts/unfinished`);
  };

  deleteShoppingCart = (shoppingCartUuid: string) => this.http.delete(`v1.0/shopping-carts/${shoppingCartUuid}`);

  postReservation = (shopUuid: string, reservation: Reservation, source: string, campaign: string) => {
    let params = new HttpParams();

    if (source) {
      params = params.append('source', source);
    }

    if (campaign) {
      params = params.append('campaign', campaign);
    }

    return this.http.post<ReservationResponse>(`v1.0/shops/${shopUuid}/reservations/${reservation.product_uuid}`, reservation, {
      params: params,
    });
  };

  deleteReservation = (shoppingCartUuid: string, reservationUuid: string) =>
    this.http.delete<ReservationResponse>(`v1.0/shopping-carts/${shoppingCartUuid}/reservations/${reservationUuid}`);

  getPaymentsMethod = (shoppingCartUuid: string) =>
    this.http.get<PaymentMethod[]>(`v1.0/shopping-carts/${shoppingCartUuid}/payment-methods`);

  getPaymentMethod = (shoppingCartUuid: string, paymentMethodName: string) =>
    this.http.get<ShoppingCart>(`v1.0/shopping-carts/${shoppingCartUuid}?payment_method_name=${paymentMethodName}`);

  putManualDetails = (shoppingCartUuid: string, user: User) => this.http.put(`v1.0/shopping-carts/${shoppingCartUuid}/buyer`, user);

  postResetPassword = (resetPasswordRequest: ResetPasswordRequest) =>
    this.http.post(`rest-api/v1/users/reset_password`, resetPasswordRequest);

  postOrder = (orderRequest: OrderRequest) => this.http.post<OrderResponse>(`v1.0/orders`, orderRequest);

  getOrder = (orderUuid: string, withSalt = false) => {
    const salt = new Date().getTime();
    return this.http.get<OrderResponse>(`v1.0/orders/${orderUuid}${withSalt ? '?' + salt : ''}`);
  };
  getOrderResponse = (orderUuid: string, withSalt = false) => {
    const salt = new Date().getTime();
    return this.http.get<OrderResponse>(`v1.0/orders/${orderUuid}${withSalt ? '?' + salt : ''}`, { observe: 'response' });
  };

  getOrderDeeplink = (orderUuid: string) => {
    return this.http.get<string>(`rest-api/v1/ticketing/order/${orderUuid}/deeplink`, { responseType: 'text' as any });
  };

  postEmailValidation = (email: EmailValidation) => this.http.post(`api/emails/validations`, email);

  postSession = (sessionRequest: SessionRequest) => this.http.post<SessionResponse>(`v1.0/shop-sessions`, sessionRequest);

  getShopEvents = (params?) => this.http.get<{ count: number; results: ShopEvent[] }>(`api/web/shops`, { params: params || {} });

  bindOrder = (context: AuthenticationContext) => this.http.post(`rest-api/v1/shop/bind_order`, { context });

  downloadTicket = (productUuid) => {
    return this.http.get(`api/orders/order-lines/${productUuid}/download`, { responseType: 'blob' });
  };
}
